import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "sampleReception" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoRecSampleList = _resolveComponent("NoRecSampleList")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_RecedSampleList = _resolveComponent("RecedSampleList")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_tabs, {
      sticky: "",
      "offset-top": "0px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "未接收" }, {
          default: _withCtx(() => [
            _createVNode(_component_NoRecSampleList)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "已接收" }, {
          default: _withCtx(() => [
            _createVNode(_component_RecedSampleList)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}