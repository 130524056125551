const idCardTypes = [{
    name: '身份证',
    code: '01'
}, {
    name: '军人证',
    code: '02'
}, {
    name: '港澳台身份证',
    code: '03'
}, {
    name: '华侨身份证',
    code: '04'
}, {
    name: '外籍护照',
    code: '05'
}];

const formatIdCardType = function (type: string) {
    const res = idCardTypes.filter((p: any) => p.code === type);
    if (res.length === 1) {
        return res[0].name;
    } else {
        return '';
    }
};

// 患者属于
const belongTos = [
    { code: 0, name: '本县区' },
    { code: 1, name: '本市其它县区' },
    { code: 2, name: '本省其它城市' },
    { code: 3, name: '外省' },
    { code: 4, name: '港澳台' },
    { code: 5, name: '外籍' }];

const testCounts = [
    { code: 0, name: '第1次检测' },
    { code: 1, name: '第2次检测' },
    { code: 2, name: '第3次检测' },
    { code: 3, name: '第4次检测' },
    { code: 4, name: '第5次检测' },
    { code: 5, name: '第6次检测' },
    { code: 6, name: '第7次检测' },
    { code: 7, name: '第8次检测' },
    { code: 8, name: '第9次检测' },
    { code: 9, name: '第10次检测' },
    { code: 10, name: '第11次检测' },
    { code: 11, name: '第12次检测' },
    { code: 12, name: '第13次检测' },
    { code: 13, name: '第14次检测' },
    { code: 14, name: '第15次检测' },
    { code: 15, name: '第16次检测' },
    { code: 16, name: '第17次检测' },
    { code: 17, name: '第18次检测' },
    { code: 18, name: '第19次检测' },
    { code: 19, name: '第20次检测' }
];

// 采集样本类型
const specTypes = [
    { code: 0, name: '咽拭子' },
    { code: 1, name: '鼻拭子' },
    { code: 11, name: '鼻咽双采' },
    { code: 2, name: '鼻咽抽取物或呼吸道抽取物' },
    { code: 3, name: '深咳痰液' },
    { code: 4, name: '支气管灌洗液' },
    { code: 5, name: '肺泡灌洗液' },
    { code: 6, name: '血液样本' },
    { code: 7, name: '血清样本' },
    { code: 8, name: '粪便样本' },
    { code: 9, name: '眼结膜拭子样本' },
    { code: 10, name: '其他材料' }];

const getSpecType = function (specTypeName: string) {
    if (!specTypeName || specTypeName.length === 0) {
        return '';
    }
    switch (specTypeName) {
        case '咽拭子':
            return 0;
        case '鼻拭子':
            return 1;
        case '鼻咽抽取物或呼吸道抽取物':
            return 2;
        case '深咳痰液':
            return 3;
        case '支气管灌洗液':
            return 4;
        case '肺泡灌洗液':
            return 5;
        case '血液样本':
            return 6;
        case '血清样本':
            return 7;
        case '粪便样本':
            return 8;
        case '眼结膜拭子样本':
            return 9;
        case '其他材料':
            return 10;
        case '鼻咽双采':
            return 11;
        default:
            return 10;
    }
};

const collectTypes = [
    { code: 0, name: '重点疫情地区来茌返茌人员' },
    { code: 1, name: '河北来茌人员接触者' },
    { code: 2, name: '有发热症状和购买、使用退热、咳嗽药品人员' }
];

const collectStatus = {
    Collected: 0, //已采集
    Tested: 4,   //已检测
    Audited: 6,   //已审核  暂时不用 2021-02-05
    Published: 8   //已发布
};

const eCollectStatus = [
    { code: 0, name: '已采集，未检测' },
    { code: 4, name: '已检测，未发布' },
    { code: 6, name: '已审核' },  //暂时不用 2021-02-05
    { code: 8, name: '已发布' }
];

// 格式化检测状态
const formatStatus = function (status: any) {
    if (status === null) {
        return '无';
    }
    const temp = eCollectStatus.filter((p: any) => p.code === status);
    if (temp.length === 1) {
        return temp[0].name;
    }
}

// 格式化结果
const formatResult = function (status: any, result: any) {
    if (status !== collectStatus.Published) {
        return '无';
    }
    switch (result) {
        case null: return '';
        case 0: return '阴性';
        case 1: return '阳性';
    }
}

const envTypes = [
    {
        code: '0', name: '公共场所环境', children: [
            { code: '0', name: '本院内' },
            { code: '1', name: '村卫生室' },
            { code: '2', name: '个人诊所' },
            { code: '3', name: '农贸市场' },
            { code: '4', name: '村民活动室' },
            { code: '5', name: '棋牌室' },
            { code: '6', name: '休闲娱乐室' },
            { code: '7', name: '公共卫生间' },
            { code: '8', name: '养老院' },
            { code: '20', name: '其他' }
        ]
    },
    { code: '1', name: '冷链环境' },
    { code: '2', name: '非冷链环境' },
    { code: '3', name: '冷链食品' },
    { code: '4', name: '非冷链食品' }
];

const goodsSpecTypes = [
    { code: 0, name: '拭子' }
];

const SampleSpecimenStatus = [{
    name: '未接收',
    code: '0'
},
{
    name: '未接收',
    code: '1'
}, {
    name: '已接收',
    code: '5'
}, {
    name: '拒收',
    code: '10'
}, {
    name: '已检测任务分配',
    code: '15'
}, {
    name: '样本已接收',
    code: '16'
}, {
    name: '部分录入',
    code: '20'
}, {
    name: '已全部录入',
    code: '25'
}];
const SampleStatus = [{
    name: '未采样',
    code: '0'
},
{
    name: '采样中',
    code: '5'
}, {
    name: '采样完成',
    code: '10'
}, {
    name: '样本退回',
    code: '15'
}, {
    name: '全部接收',
    code: '20'
}, {
    name: '已全部录入',
    code: '25'
}];
const SampleModes = [{
    name: '现场采样',
    code: '0'
}, {
    name: '送样/邮寄',
    code: '1'
}];

const ApplicationStatus = [{
    name: '暂存',
    code: '0'
}, {
    name: '待受理',
    code: '5'
}, {
    name: '退回',
    code: '10'
}, {
    name: '已受理',
    code: '15'
}, {
    name: '分派中',
    code: '20'
}, {
    name: '分派完成',
    code: '25'
}, {
    name: '采样检测中',
    code: '30'
}, {
    name: '已全部录入',
    code: '35'
}, {
    name: '已生成结论',
    code: '40'
}, {
    name: '报告暂存',
    code: '45'
}, {
    name: '审核不通过',
    code: '46'
}, {
    name: '编制待审核',
    code: '50'
}, {
    name: '签发不通过',
    code: '51'
}, {
    name: '审核待签发',
    code: '55'
}, {
    name: '签发待归档',
    code: '60'
}, {
    name: '已归档',
    code: '65'
}, {
    name: '已归档',
    code: '70'
}
];

const inconWorkStatus=[{
    name: '暂存',
    code: '0'
  }, {
    name: '已提交',
    code: '5'
  }, {
    name: '已评价调查',
    code: '10'
  }, {
    name: '驳回',
    code: '11'
  }, {
    name: '审核中',
    code: '14'
  }, {
    name: '已纠正',
    code: '15'
  }, {
    name: '已实施',
    code: '20'
  }, {
    name: '已验证',
    code: '25'
  }
  ];

export { idCardTypes, belongTos, testCounts, specTypes, getSpecType, collectTypes, collectStatus, eCollectStatus, formatStatus, formatResult, formatIdCardType, envTypes, goodsSpecTypes, SampleSpecimenStatus, SampleStatus, SampleModes, ApplicationStatus,inconWorkStatus };