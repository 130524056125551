
import { Options, Vue } from "vue-class-component";
import { SampleStatus } from "@/middlewares/enum";
import SampleInfo from "./SampleInfo.vue";
import { Notify, Toast } from "vant";
import moment from "moment";
@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
    SampleInfo,
  },
})
export default class NoRecSampleList extends Vue {
  private sampleStatus = SampleStatus;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private SampleList: any = [];
  private sltSampleList: any = [];
  private pageIndex = 1;
  private allChecked = false;
  private searchDateType = "today";
  private searchForm: any = {
    code: "",
    name: "",
  };
  private findCovidCollectList() {
    if (this.refreshing) {
      this.SampleList = [];
      this.refreshing = false;
    }
    this.loading = true;
    const url = "/api/lims/applicationSample/getSampleNewPageList";
    this.axios
      .get(url, {
        params: {
          code: this.searchForm.code,
          checkOrgId: this.$store.state.userInfo.orgId,
          sampleStatus: "10",
          size: 20,
          current: this.pageIndex,
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          for (const record of res.data.records) {
            record.checked = false;
          }
          this.SampleList = this.SampleList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.SampleList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private submitForm() {
    this.sltSampleList = [];
    for (const sp of this.SampleList) {
      const sb: any = sp;
      if (sb.checked) {
        this.sltSampleList.push(sb);
      }
    }
    if (this.sltSampleList.length === 0) {
      Toast("请选择需要接收的样品");
      return;
    }

    this.$dialog
      .confirm({
        title: "确认",
        message: "确认要接收样品么？",
      })
      .then(() => {
        const obj: any = {
          sampleList: this.sltSampleList,
          receiptTime: moment().format("YYYY-MM-DD"),
        };
        const url =
          "/api/lims/applicationSampleApproval/batchAddApplicationSampleApproval";
        this.axios
          .post(url, obj)
          .then((res: any) => {
            if (res.status.code === 1) {
              Toast("接收成功");
              this.sltSampleList = [];
              this.allChecked = false;
              this.doSearch();
            } else {
              Notify(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(() => {
        console.log("cancel delete");
      });
  }

  private doSearch() {
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  // 格式化检测状态
  //获取样品状态字典
  private getSampleStatusDesc(scope: any) {
    for (const item of this.sampleStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private formatDataTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }
  private checkAllChange(event: MouseEvent) {
    for (const sp of this.SampleList) {
      sp.checked = this.allChecked;
    }
  }
  private checkOneChange(event: MouseEvent) {
    let isAllCheck = true;
    for (const sp of this.SampleList) {
      if (!sp.checked) {
        isAllCheck = false;
        break;
      }
    }
    this.allChecked = isAllCheck;
  }
  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findCovidCollectList();
  }
  //重置查询
  private resetSearch() {
    this.searchDateType = "today";
    this.searchForm = {
      code: "",
      name: "",
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  private showSample(item: any) {
    //打开样品详情
    const temp: any = this.$refs.sampleInfoRef;
    temp.showSampleInfo(item);
  }
}
