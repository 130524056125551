import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "sampleInfo" }
const _hoisted_2 = { style: {"margin":"15px"} }
const _hoisted_3 = { style: {"margin":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom",
      closeable: "",
      "close-icon-position": "top-right",
      teleport: "#app",
      style: { width: '100%', height: '70%' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, { title: "申请单信息" }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell, {
              title: "样品编码",
              value: 
            _ctx.applyForm.sampleList.length > 0 ? _ctx.applyForm.sampleList[0].code : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "样品名称",
              value: 
            _ctx.applyForm.sampleList.length > 0 ? _ctx.applyForm.sampleList[0].name : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "样品分类",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].sampleTypeName
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "检测目的",
              value: _ctx.applyForm.inspectGoal
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "采样日期",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].samplingTime
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "采样人",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].sampler
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "预计完成时间",
              value: this.formatDateToDays(_ctx.applyForm.requiredFinishTime)
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "送样方式",
              value: _ctx.getSampleModeDesc(_ctx.applyForm.sampleMode)
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "检测机构",
              value: _ctx.applyForm.checkOrgName
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_van_button, {
            round: "",
            block: "",
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("接收")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_van_button, {
            round: "",
            block: "",
            type: "default",
            onClick: _ctx.cancelForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}