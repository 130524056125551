
import { Options, Vue } from "vue-class-component";
import { SampleStatus } from "@/middlewares/enum";
import moment from "moment";
export default class UserCollectPersonList extends Vue {
  private sampleStatus = SampleStatus;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private SampleList = [];
  private pageIndex = 1;
  private searchDateType = "today";
  private searchForm: any = {
    code: "", //采集编号
    name: "", //采集居民
    startDate: moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };
  private dateTypes = [
    { text: "昨天", value: "yesterday" },
    { text: "今天", value: "today" },
    { text: "本周", value: "week" },
    { text: "本月", value: "month" },
  ];
  private findCovidCollectList() {
    if (this.refreshing) {
      this.SampleList = [];
      this.refreshing = false;
    }

    this.loading = true;
    const url = "/api/lims/applicationSample/getSampleNewPageList";
    const statusArrys = ["20", "25"];
    this.axios
      .get(url, {
        params: {
          code: this.searchForm.code,
          statusArrys: statusArrys.join(","),
          size: 20,
          current: this.pageIndex,
          checkOrgId: this.$store.state.userInfo.orgId,
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          this.SampleList = this.SampleList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.SampleList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }
  //切换日期范围
  private async resetSearchDate(type: any) {
    switch (type) {
      case "yesterday":
        this.searchForm.startDate = moment(new Date())
          .add(-1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.searchForm.endDate = moment(new Date())
          .add(-1, "days")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case "today":
        this.searchForm.startDate = moment(new Date())
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.searchForm.endDate = moment(new Date())
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case "week":
        this.searchForm.startDate = moment()
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss");
        this.searchForm.endDate = moment()
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case "month":
        this.searchForm.startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.searchForm.endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
    }
    this.refreshing = true;
    this.onRefresh();
  }

  private doSearch() {
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  // 格式化检测状态
  //获取样本状态字典
  private getSampleStatusDesc(scope: any) {
    for (const item of this.sampleStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private formatDataTime(time: any) {
    console.log(time);
    return moment(time).format("YYYY-MM-DD");
  }

  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findCovidCollectList();
  }
  //重置查询
  private resetSearch() {
    this.searchDateType = "today";
    this.searchForm = {
      code: "", //采集编号
      name: "", //采集居民
      startDate: moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }
}
